<template>
  <v-card elevation="8" class="mt-5 mb-2 mx-auto" max-width="1044">
    <v-card-title class="text-button">My numbers</v-card-title>
    <v-divider class="mx-4"></v-divider>
    <maintenancePlug />
  </v-card>
</template>

<script>
//import { useStore } from "vuex";
//import { computed } from "vue";
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";
import maintenancePlug from "../components/maintenancePlug.vue"

export default {
  name: "NumbersView",
  components: {
    maintenancePlug: maintenancePlug
  },
  setup() {
    //const store = useStore();
    //const messages = computed(() => store.state.local.api.messages);
    //const profiles = computed(() => store.state.local.api.profiles);
    //console.log(store.state.local.api.selected_group)


    return {
      //profiles,
      //messages,
    };
  },
  mounted() {
    UserService.getRegistratorBoard().then(
      response => {
        console.log(response)
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response && error.response.status === 403) {

          EventBus.dispatch("logout");
        }
      }
    );
  },
  data: () => ({
    selected: [],
    loading: false,
    search: "",
    content: "",
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    filtredProfiles() {
      const search = this.search.toLowerCase();

      if (!search) return this.profiles;

      return this.profiles.filter((item) => {
        const output = item.name.toLowerCase();

        return output.indexOf(search) > -1;
      });
    },

  },

  watch: {
    selected() {
      this.search = "";
    },
  },

  methods: {
    filtredMessages(ProfileName) {
      const search = ProfileName.toLowerCase();

      //if (!search) return this.profiles

      return this.messages.filter((item) => {
        const output = item.name.toLowerCase();

        return output.indexOf(search) > -1;
      });
    },
    load(val) {
      if (!val) this.loading = true;
      setTimeout(() => (this.loading = false), 1000);
    },
  },
};
</script>

<style></style>
