<template>
    
    <v-col cols="12" align-self="center" class="text-center ">
        <v-row no-gutters align="center" justify="center" style="height: 700px;">
            under maintenance
        </v-row>
    </v-col>

</template>

<script>
//import { useStore } from "vuex";
//import { computed } from "vue";
import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
    name: "NumbersView",
    setup() {
        //const store = useStore();
        //const messages = computed(() => store.state.local.api.messages);
        //const profiles = computed(() => store.state.local.api.profiles);
        //console.log(store.state.local.api.selected_group)


        return {
            //profiles,
            //messages,
        };
    },
    mounted() {
        UserService.getRegistratorBoard().then(
            response => {
                console.log(response)
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {

                    EventBus.dispatch("logout");
                }
            }
        );
    },
    data: () => ({
        loading: false,
        content: "",
    }),
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },

    },

    watch: {
    },

    methods: {
        load(val) {
            if (!val) this.loading = true;
            setTimeout(() => (this.loading = false), 1000);
        },
    },
};
</script>

<style></style>