<template>
    <v-card :elevation="checkEmpty(this.sessions) == 0 ? 0 : 8" class="mt-5 mb-2 mx-auto" max-width="1044">
        <v-text-field ref="search" v-model="search" hide-details label="Search" single-line></v-text-field>
        <v-row dense>
            <v-col cols="12">
                <v-progress-linear :active="load(this.sessions)" :indeterminate="loading" bottom
                    color="indigo-darken-4"></v-progress-linear>
            </v-col>
            <v-col cols="12" v-if="checkEmpty(this.sessions) == 0" align-self="center" class="text-center ">
                <v-row no-gutters align="center" justify="center" style="height: 600px;">
                    no active sessions yet
                </v-row>
            </v-col>
            <v-col v-for="session in filtredSessions" :key="session.fixed_simnum" cols="12">
                <v-card elevation="3">
                    <v-card-title class="text-overline">
                        <v-row justify="end" align="center">
                            <v-col class="text-left pb-2" cols="6">
                                {{ session.fixed_simnum }} {{ session.drops ? `[${session.drops}]` : `` }}
                                <v-icon :color="session.gsm_status == 'login' && session.cgatt == 'y'  ? 'success' : 'red-darken-2'"
                                    icon="mdi-circle-medium"></v-icon>
                            </v-col>
                            <v-col class="text-right pb-2" cols="6">
                                <v-btn :append-icon="session.show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                                    color="indigo-darken-2" variant="text" @click="session.show = !session.show">
                                    Details
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-divider class="mx-4"></v-divider>

                    <v-expand-transition>
                        <div v-show="session.show">
                            <div class="pt-2 px-4 d-flex align-center">
                                <v-icon :color="session.gsm_status == 'login' ? 'success' : 'red-darken-2'"
                                    icon="mdi-circle-medium"></v-icon>
                                <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                    GSM 
                                </span>
                                <span v-if="!smAndDown" class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                    {{ session.gsm_status }} {{ session.pro ? `[${session.pro}]` : `` }}
                                </span>
                                <span class="text-caption text-medium-emphasis ms-1 font-weight-light"> | </span>
                                <v-icon :color="session.cgatt == 'y' ? 'success' : 'red-darken-2'"
                                    icon="mdi-circle-medium"></v-icon>
                                <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                    GPRS 
                                </span>
                                <span v-if="!smAndDown" class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                    {{session.cgatt == 'y'? 'login': 'logout'}}
                                </span>
                                <v-spacer></v-spacer>
                                <v-btn color="indigo-darken-2" class="text-button" variant="text"
                                    @click="reload(session.line_id)">
                                    reload
                                </v-btn>
                                <v-btn color="indigo-darken-2" class="text-button" variant="text"
                                    @click="wakeup(session.sim_id)">
                                    wakeup
                                </v-btn>
                            </div>
                            <v-row no-gutters class="pt-2 px-4 d-flex align-center">
                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-account-box-outline"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        user: {{ session.current_user }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-signal"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        signal: {{ session.signal }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-identifier"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        session id: {{ session.task_id }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-identifier"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        line id: {{ session.line_id.slice(-2) }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-identifier"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        bank id: {{ session.sim_id.slice(0, 2) }}
                                    </span>
                                </v-col>

                                <v-col cols="6" md="4">
                                    <v-icon color="grey" icon="mdi-identifier"></v-icon>
                                    <span class="text-caption text-medium-emphasis ms-1 font-weight-light">
                                        sim id: {{ session.sim_id.slice(-3) }}
                                    </span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-expand-transition>

                    <v-card-actions class="justify-center">
                        <v-btn block variant="tonal" color="indigo-darken-2" @click="kill(session.task_id)">
                            cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- Snackbar для уведомлений -->
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3000">
            {{ snackbar.text }}
        </v-snackbar>
    </v-card>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { useDisplay } from 'vuetify'

import UserService from '../services/user.service';
import EventBus from "../common/EventBus";

export default {
    name: "LinesView",
    setup() {
        const store = useStore();
        const { smAndDown } = useDisplay();
        store.dispatch("sessions/getAll");

        const sessions = computed(() => store.state.sessions.all);
        return {
            sessions,
            smAndDown
        };
    },
    data() {
        return {
            show: true,
            loading: false,
            search: "",
            content: "",
            snackbar: {
                show: false,
                text: '',
                color: 'grey darken-1', // Нейтральный цвет по умолчанию
            },
        };
    },
    mounted() {
        UserService.getManagerBoard().then(
            response => console.log(response),
            error => {
                this.content = error?.response?.data?.message || error.message || error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        filtredSessions() {
            const search = this.search.toLowerCase();

            if (!search) return this.sessions;

            return this.sessions.filter((item) => item.fixed_simnum.toLowerCase().includes(search));
        },
    },
    methods: {
        load(val) {
            if (!val) this.loading = true;
            setTimeout(() => (this.loading = false), 1000);
        },
        async kill(task_id) {
            try {
                await this.$store.dispatch("sessions/kill", task_id);
                this.showSnackbar(`Session ${task_id} canceled successfully!`, 'success');
            } catch (error) {
                this.showSnackbar(`Failed to cancel session ${task_id}.`, 'error');
            }
        },
        async wakeup(sim_id) {
            try {
                await this.$store.dispatch("sessions/wakeup", sim_id);
                this.showSnackbar(`SIM ${sim_id} woken up successfully!`, 'success');
            } catch (error) {
                this.showSnackbar(`Failed to wake up SIM ${sim_id}.`, 'error');
            }
        },
        async reload(line_id) {
            try {
                await this.$store.dispatch("sessions/reload", line_id);
                this.showSnackbar(`Line ${line_id} reloaded successfully!`, 'success');
            } catch (error) {
                this.showSnackbar(`Failed to reload line ${line_id}.`, 'error');
            }
        },
        checkEmpty(sessions) {
            if (!sessions) this.loading = true;
            setTimeout(() => (this.loading = false), 1000);
            return sessions ? sessions.length : 0;
        },
        showSnackbar(text, status = 'success') {
            this.snackbar.text = text;
            this.snackbar.color = status === 'success' ? 'green-lighten-1' : 'red darken-1';
            this.snackbar.show = true;
        },
    },
};
</script>

<style></style>
